import request from '@/utils/request'
/**
 *   vip模块 api
 */
// 会员充值
export const rechargeListApi = params =>
  request({
    url: '/member/recharge',
    method: 'get',
    params
  })

// 购买会员
export const buyVip = params =>
  request({
    url: `/member/buy/${params.priceId}`,
    method: 'post'
  })
